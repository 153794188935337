// Working file, delete later
import Main from '@/views/Main.vue';
// Working file, delete later

import LoginFrame from '@/views/LoginFrame.vue';
import Login from '@/views/login/Login.vue';
import ResetPassword from '@/views/login/resetPassword.vue';

import Frame from '@/views/frame.vue';

import Dashboard from '@/views/Dashboard.vue';

import Items from '@/views/Items.vue';
import prebook from '@/views/prebook.vue';
import itemsCategorySelector from '@/views/itemsCategorySelector.vue';

import cart from '@/views/cart/cart.vue';
import cartItem from '@/views/cart/item.vue';
import cartOrderConfirmation from '@/views/cart/orderConfirmation.vue';
import cartOrderSuccess from '@/views/cart/orderSuccess.vue';

import prebookCheckout from '@/views/orderbook/checkout.vue';
import prebookConfirmation from '@/views/orderbook/confirmation.vue';
import prebookSuccess from '@/views/orderbook/success.vue';

import pageNotFound from '@/views/pageNotFound.vue';

import notifications from '@/views/notifications.vue';

import sharepointDrives from '@/views/account/about/sharepoint/sharepointDrives.vue';
import sharepointItems from '@/views/account/about/sharepoint/sharepointItems.vue';

import reset from '@/views/reset.vue';

// Customizer(Build your own)
import buildYourOwn from '@/views/customizer/buildYourOwn.vue';
import buildYourOwnModels from '@/views/customizer/buildYourOwnModels.vue';
// let entryUrl = null;

import { track } from '@/api/notifications';
import MobieTrain from '@/views/mobieTrain.vue';
import store from './store';

const itemdetail = () => import(/* webpackChunkName: "itemdetail" */ '@/views/itemDetail.vue');

const myWarranties = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/account/mywarranties/myWarranties.vue');
const newWarranty = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/account/mywarranties/new.vue');
const detailWarranty = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/account/mywarranties/view.vue');

const myInvoicesView = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/account/myinvoices/view.vue');
const myInvoices = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/account/myinvoices/myInvoices.vue');

const myOrdersView = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/account/myorders/view.vue');
const myOrders = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/account/myorders/myOrders.vue');
const myOrdersBalance = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/account/myorders/myBalance.vue');

const myBackOrders = () => import(/* webpackChunkName: "incomingBikes" */ '@/views/account/account/mybackorders/backOrders.vue');
const editPreorder = () => import(/* webpackChunkName: "incomingBikes" */ '@/views/account/account/mybackorders/editPrebookItem.vue');

const myStockNew = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/account/mystock/newStock.vue');
const myStock = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/account/mystock/myStock.vue');

const myCustomers = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/account/mycustomers/myCustomers.vue');
const myCustomersDetail = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/account/mycustomers/customer.vue');

const myStatsView = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/account/mystats/myStats.vue');

const myExports = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/account/myExports.vue');

const accountEdit = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/settings/edit.vue');

const accountReleaseNotes = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/about/releaseNotes.vue');

const accountHelpAndSupport = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/about/helpAndSupport.vue');
const accountHelpAndSupportRep = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/about/helpAndSupport/rep.vue');
const accountHelpAndSupportContact = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/about/helpAndSupport/contact.vue');
const accountHelpAndSupportHours = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/about/helpAndSupport/hours.vue');

const accountTargets = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/about/targets.vue');

const accountNewsAndUpdates = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/about/newsAndUpdates.vue');
const accountDetailNewsAndActions = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/about/DetailNewsAndActions/view.vue');
const accountSocialFeeds = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/about/socialFeeds.vue');
const accountDetailSocialFeed = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/about/DetailSocialFeed/view.vue');

const accountChange = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/settings/change.vue');

const accountLocations = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/settings/locations.vue');
const accountLocationsView = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/settings/locations/view.vue');
const accountLocationsNew = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/settings/locations/new.vue');

const accountVouchers = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/settings/vouchers.vue');
const accountVouchersView = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/settings/vouchers/view.vue');
const accountVouchersNew = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/settings/vouchers/new.vue');

const accountCompanyDetails = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/settings/companyDetails.vue');
const accountMyApiSettings = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/settings/apiCustomerKey.vue');

const accountChangePassword = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/settings/changePassword.vue');
const accountMyBalance = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/account/myBalance.vue');

const accountMyPoints = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/account/myPoints.vue');
const accountMyContract = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/account/myContract.vue');

const accountNotifications = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/settings/notifications.vue');

const account = () => import(/* webpackChunkName: "myAccount" */ '@/views/account.vue');

const mobieTrain = () => import('@/views/mobieTrain.vue');

export const afterEach = (to, from) => {
  // ...
  track(to.path);
  store.commit('global/previousRoute', from && from.path !== '/' ? from.path : null);
};

const guard = async (to, from, next) => {
  if (
    store.getters['auth/isLoggedIn']
    && store.state.customer.selectedCustomerId
  ) {
    next();
  } else if (['reset', 'login'].includes(to.name)) {
    next({ name: 'login' });
  } else {
    next({ name: 'login', params: { nextRoute: to.path, query: to.query } });
  }
};

export const routes = [
  { path: '/index.php/site/login', redirect: { name: 'login' } }, // marketing yey
  {
    path: '',
    beforeEnter: guard,
    component: Frame,
    children: [
      { path: '/', redirect: '/dashboard' },
      {
        path: '/dashboard',
        name: 'dashboard',
        beforeEnter: guard,
        component: Dashboard,
        children: [
          {
            path: '/dashboard/news/:id',
            beforeEnter: guard,
            name: 'News',
            component: accountDetailNewsAndActions,
            props: true,
            meta: {
              keepPosition: true,
            },
          },
          {
            path: '/dashboard/socialFeeds/:id',
            beforeEnter: guard,
            name: 'DashboardSocialFeedsDetail',
            component: accountDetailSocialFeed,
            props: true,
            meta: {
              keepPosition: true,
            },
          },
        ],
      },
      {
        path: '/reset',
        name: 'reset',
        component: reset,
      },
      {
        path: '/notifications',
        name: 'notifications',
        beforeEnter: guard,
        component: notifications,
      },
      {
        path: '/items',
        name: 'items',
        beforeEnter: guard,
        component: itemsCategorySelector,
      },
      {
        path: '/ssp/:brand',
        name: 'prebook',
        beforeEnter: guard,
        component: prebook,
        props: {
          defaultFilters: [{ category: 'bikes' }, { preorder: 'yes' }],
        },
      },
      {
        path: '/prebook/:brand', // old url for backwards compatibility
        name: 'prebook',
        beforeEnter: guard,
        component: prebook,
        props: {
          defaultFilters: [{ category: 'bikes' }, { preorder: 'yes' }],
        },
      },
      {
        path: '/ssp/:brand/checkout',
        name: 'prebook_checkout',
        beforeEnter: guard,
        component: prebookCheckout,
      },
      {
        path: '/ssp/:brand/confirmation',
        name: 'prebook_confirmation',
        beforeEnter: guard,
        component: prebookConfirmation,
      },
      {
        path: '/ssp/:brand/success',
        name: 'prebook_success',
        beforeEnter: guard,
        component: prebookSuccess,
      },
      {
        path: '/items/bikes',
        beforeEnter: guard,
        name: 'bikeItems',
        component: Items,
        props: {
          default: true,
          defaultFilters: [{ category: 'bikes' }],
          title: 'items.cat.allBikes',
        },
      },
      {
        path: '/items/accessories',
        beforeEnter: guard,
        name: 'accessoryItems',
        component: Items,
        props: {
          default: true,
          defaultFilters: [{ category: 'accessories' }],
          title: 'items.cat.accessories',
        },
      },
      {
        path: '/items/parts',
        beforeEnter: guard,
        name: 'partItems',
        component: Items,
        props: {
          default: true,
          defaultFilters: [{ category: 'components' }],
          title: 'items.cat.components',
        },
      },
      {
        path: '/items/outlet',
        beforeEnter: guard,
        name: 'outletItems',
        component: Items,
        props: {
          default: true,
          defaultFilters: [{ category: 'outlet' }],
          title: 'items.cat.outlet',
        },
      },
      {
        path: '/items/promo',
        beforeEnter: guard,
        name: 'promoItems',
        component: Items,
        props: {
          default: true,
          defaultFilters: [{ category: 'marketing' }],
          title: 'items.cat.promoMaterial',
        },
      },
      {
        path: '/items/configurator',
        beforeEnter: guard,
        name: 'buildYourOwnModels',
        component: buildYourOwnModels,
      },
      {
        path: '/items/configurator/:model',
        beforeEnter: guard,
        name: 'buildYourOwn',
        component: buildYourOwn,
      },
      {
        path: '/items/:itemid',
        name: 'itemdetail',
        component: itemdetail,
        alias: [
          '/items/bikes/:itemid',
          '/items/accessories/:itemid',
          '/items/parts/:itemid',
          '/items/outlet/:itemid',
          '/items/promo/:itemid',
        ],
        props: true,
      },
      {
        path: '/account',
        name: 'account',
        beforeEnter: guard,
        component: account,
        children: [
          {
            path: '/account/edit',
            beforeEnter: guard,
            name: 'accountEdit',
            component: accountEdit,
          },
          {
            path: '/account/change',
            beforeEnter: guard,
            name: 'accountChange',
            component: accountChange,
          },
          {
            path: '/account/changePassword',
            beforeEnter: guard,
            name: 'changePassword',
            component: accountChangePassword,
          },
          {
            path: '/account/companyDetails',
            beforeEnter: guard,
            name: 'companyDetails',
            component: accountCompanyDetails,
          },
          {
            path: '/account/apiCustomerKey',
            beforeEnter: guard,
            name: 'apiCustomerKey',
            component: accountMyApiSettings,
          },
          {
            path: '/account/releases',
            beforeEnter: guard,
            name: 'releaseNotes',
            component: accountReleaseNotes,
          },
          {
            path: '/account/myBalance',
            beforeEnter: guard,
            name: 'myBalance',
            component: accountMyBalance,
          },
          {
            path: '/account/myPoints',
            beforeEnter: guard,
            name: 'myPoints',
            component: accountMyPoints,
          },
          {
            path: '/account/contracts',
            beforeEnter: guard,
            name: 'myContract',
            component: accountMyContract,
          },
          {
            path: '/account/exports',
            beforeEnter: guard,
            name: 'myExports',
            component: myExports,
          },
        ],
      },
      {
        path: '/account/locations',
        beforeEnter: guard,
        name: 'accountLocations',
        component: accountLocations,
        children: [
          {
            path: '/account/locations/new',
            beforeEnter: guard,
            name: 'accountLocationsViewNew',
            component: accountLocationsNew,
          },
        ],
      },
      {
        path: '/account/locations/:id',
        beforeEnter: guard,
        name: 'accountLocationsView',
        component: accountLocationsView,
      },
      {
        path: '/account/vouchers',
        beforeEnter: guard,
        name: 'accountVouchers',
        component: accountVouchers,
        children: [
          {
            path: '/account/vouchers/new',
            beforeEnter: guard,
            name: 'accountVouchersViewNew',
            component: accountVouchersNew,
          },
          {
            path: '/account/vouchers/:voucher',
            beforeEnter: guard,
            props: true,
            name: 'accountVouchersView',
            component: accountVouchersView,
            meta: {
              keepPosition: true,
            },
          },
        ],
      },
      {
        path: '/account/support',
        beforeEnter: guard,
        name: 'helpAndSupport',
        component: accountHelpAndSupport,
        children: [
          {
            path: '/account/support/rep',
            beforeEnter: guard,
            name: 'helpAndSupportRep',
            component: accountHelpAndSupportRep,
          },
          {
            path: '/account/support/hours',
            beforeEnter: guard,
            name: 'helpAndSupportHours',
            component: accountHelpAndSupportHours,
          },
          {
            path: '/account/support/contact',
            beforeEnter: guard,
            name: 'helpAndSupportContact',
            component: accountHelpAndSupportContact,
          },
        ],
      },
      {
        path: '/account/mybackorders',
        beforeEnter: guard,
        name: 'myBackOrders',
        component: myBackOrders,
        children: [
          {
            path: '/account/orders/:id',
            beforeEnter: guard,
            name: 'myBackOrdersView',
            props: true,
            component: myOrdersView,
            meta: {
              keepPosition: true,
            },
          },
          {
            path: '/account/editpreorder/:orderId/:orderlineId/:orderlineBikeIndex',
            beforeEnter: guard,
            name: 'editPreorder',
            props: true,
            component: editPreorder,
            meta: {
              keepPosition: true,
            },
          },
        ],
      },
      {
        path: '/account/orders',
        beforeEnter: guard,
        name: 'myOrders',
        component: myOrders,
        children: [
          {
            path: '/account/orders/:id',
            beforeEnter: guard,
            name: 'myOrdersView',
            props: true,
            component: myOrdersView,
            meta: {
              keepPosition: true,
            },
          },
          {
            path: '/account/orders/myBalance',
            beforeEnter: guard,
            name: 'myOrdersBalance',
            component: myOrdersBalance,
          },
        ],
      },
      {
        path: '/account/invoices',
        beforeEnter: guard,
        name: 'myInvoices',
        component: myInvoices,
        children: [
          {
            path: '/account/invoices/:id',
            beforeEnter: guard,
            props: true,
            name: 'myInvoicesView',
            component: myInvoicesView,
            meta: {
              keepPosition: true,
            },
          },
        ],
      },
      {
        path: '/account/warranties',
        beforeEnter: guard,
        name: 'myWarranties',
        component: myWarranties,
        children: [
          {
            path: '/account/warranties/new',
            beforeEnter: guard,
            name: 'newWarranty',
            component: newWarranty,
            meta: {
              keepPosition: true,
            },
          },
          {
            path: '/account/warranties/:id',
            beforeEnter: guard,
            name: 'detail',
            component: detailWarranty,
            meta: {
              keepPosition: true,
            },
          },
        ],
      },
      {
        path: '/account/stats',
        beforeEnter: guard,
        name: 'myStats',
        component: myStatsView,
        children: [
        ],
      },
      {
        path: '/account/stock',
        beforeEnter: guard,
        name: 'myStock',
        component: myStock,
        children: [
          {
            path: '/account/stock/new',
            beforeEnter: guard,
            name: 'myStockNew',
            props: true,
            component: myStockNew,
            meta: {
              keepPosition: true,
            },
          },
          {
            path: '/account/stock/order/:id',
            beforeEnter: guard,
            name: 'stockOrderView',
            props: true,
            component: myOrdersView,
            meta: {
              keepPosition: true,
            },
          },
        ],
      },
      {
        path: '/account/customers',
        beforeEnter: guard,
        name: 'myCustomers',
        component: myCustomers,
        children: [
        ],
      },
      {
        // Intentionally a seperate page (whereas other /account/ subpages often work with a sidebar, this one doesn't)
        path: '/account/customers/:id',
        beforeEnter: guard,
        name: 'myCustomersDetail',
        props: true,
        component: myCustomersDetail,
        meta: {
          keepPosition: true,
        },
      },
      {
        path: '/account/newsAndUpdates',
        beforeEnter: guard,
        name: 'newsAndUpdates',
        component: accountNewsAndUpdates,
        children: [
          {
            path: '/account/newsAndUpdates/:id',
            beforeEnter: guard,
            name: 'accountDetailNewsAndUpdates',
            component: accountDetailNewsAndActions,
            props: true,
            meta: {
              keepPosition: true,
            },
          },
        ],
      },
      {
        path: '/account/socialFeeds',
        beforeEnter: guard,
        name: 'socialFeeds',
        component: accountSocialFeeds,
        children: [
          {
            path: '/account/socialFeeds/:id',
            beforeEnter: guard,
            name: 'socialFeedsDetail',
            component: accountDetailSocialFeed,
            meta: {
              keepPosition: true,
            },
          },
        ],
      },
      {
        path: '/account/notifications',
        beforeEnter: guard,
        name: 'accountNotifications',
        component: accountNotifications,
      },
      {
        path: '/cart',
        beforeEnter: guard,
        name: 'cart',
        component: cart,
        children: [
          {
            path: '/cart/:id',
            beforeEnter: guard,
            name: 'cartItem',
            component: cartItem,
            meta: {
              keepPosition: true,
            },
          },
        ],
      },
      {
        path: '/cart/confirm',
        beforeEnter: guard,
        name: 'cartConfirm',
        component: cartOrderConfirmation,
      },
      {
        path: '/cart/success',
        beforeEnter: guard,
        name: 'cartSuccess',
        component: cartOrderSuccess,
      },
      {
        path: '/account/promo',
        beforeEnter: guard,
        name: 'sharepointDrives',
        component: sharepointDrives,
        props: true,
      },
      {
        path: '/account/promo/:driveId',
        beforeEnter: guard,
        name: 'sharepointRootItems',
        component: sharepointItems,
        props: true,
      },
      {
        path: '/account/promo/:driveId/:itemId',
        beforeEnter: guard,
        name: 'sharepointItems',
        component: sharepointItems,
        props: true,
      },
      {
        path: '/account/targets',
        beforeEnter: guard,
        name: 'targets',
        component: accountTargets,
      },

    ],
  },
  {
    path: '/mobietrain', beforeEnter: guard, component: MobieTrain, name: 'mobietrain',
  },
  { path: '/main', beforeEnter: guard, component: Main },
  {
    path: '',
    name: 'loginFrame',
    component: LoginFrame,
    props: true,
    children: [
      {
        path: '/login',
        name: 'login',
        component: Login,
        props: true,
      },
      {
        path: '/resetPassword',
        name: 'resetPassword',
        component: ResetPassword,
        props: true,
      },
    ],
  },
  { path: '*', component: pageNotFound },
];
