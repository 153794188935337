<template>
  <div
    v-if="totalBalance >= creditlimit"
  >
    <SectionTitle>{{ $t('account.account.myBalance.title') }}</SectionTitle>
    <Message
      v-if="totalBalance >= creditlimit"
      type="error"
      :icon="true"
      :html-content="$t('account.account.myBalance.message')"
      :show-close="false"
    >
      <strong>{{ $t('account.account.myBalance.messagetitle1') }}</strong> {{ $t('account.account.myBalance.messagetitle2') }}
    </Message>
    <table class="table table--striped m-b-2">
      <!--       <tr>
        <td>{{ $t('account.account.myBalance.creditlimit') }}</td>
        <td
          class="nowrap text-right"
          width="1"
        >
          <price
            :currency="currency"
            :price="creditlimit"
          />
        </td>
      </tr> -->

      <tr v-if="!showRetailPrices">
        <td>{{ $t('account.account.myBalance.balance') }}</td>
        <td
          class="nowrap text-right"
          width="1"
        >
          <price
            class="form-text--error"
            :currency="currency"
            :price="totalBalance"
          />
        </td>
      </tr>
    </table>
  </div>
</template>

<style scoped></style>

<script>
import { mapGetters, mapState } from 'vuex';
import price from '@/elements/Price.vue';
import Message from '@/components/Message.vue';
import SectionTitle from '@/elements/SectionTitle.vue';

export default {
  components: {
    price,
    Message,
    SectionTitle,
  },
  data() {
    return {
      // totalBalance: 0,
      creditlimit: 0,
      currency: '',
    };
  },
  computed: {
    ...mapState({
      showRetailPrices: (state) => state.items.showRetailPrices,
      selectedCustomer: (state) => state.customer.selectedCustomer,
    }),
    ...mapGetters('cart', {
      cartPrice: 'cartPrice',
      generated: 'cartGenerated',
    }),
    totalBalance() {
      const { exchangerate } = this.selectedCustomer.attributes;
      if (this.currency !== 'EUR') {
        return ((this.selectedCustomer.attributes.BalanceProductionOrdersLCY + this.selectedCustomer.attributes.BalanceOpenInvoicesLCY) * exchangerate)
          + this.generated.toPay;
      }
      return this.selectedCustomer.attributes.BalanceProductionOrdersLCY
          + this.selectedCustomer.attributes.BalanceOpenInvoicesLCY + this.generated.toPay;
    },
  },
  mounted() {
    this.currency = this.selectedCustomer.attributes.currency;
    const { exchangerate } = this.selectedCustomer.attributes;
    if (this.currency !== 'EUR') {
      this.creditlimit = this.selectedCustomer.attributes.CreditLimitLCY * exchangerate;
    } else {
      this.creditlimit = this.selectedCustomer.attributes.CreditLimitLCY;
    }
  },
};
</script>
