<template>
  <div
    id="app"
    class="main"
    :class="{noPadding: hideFooter}"
  >
    <fresh-chat v-if="false===true" /><!--Temporarily disable freshchat-->
    <bcf-header />
    <main
      :key="selectedCustomerId"
      class="main__inner"
    >
      <!--<div class="container">-->
      <router-view />
      <!--</div>-->
    </main>
    <bcf-footer v-if="!hideFooter" />

    <BcfDialogHost />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import bcfHeader from '@/components/bcfHeader.vue';
import bcfFooter from '@/components/bcfFooter.vue';
import freshChat from '@/components/freshChat.vue';
import BcfDialogHost from '@/components/dialog/bcfDialogHost.vue';

export default {
  components: {
    bcfHeader,
    bcfFooter,
    freshChat,
    BcfDialogHost,
  },
  computed: {
    hideFooter() {
      return ['buildYourOwn'].includes(this.$route.name);
    },
    ...mapState({
      selectedCustomerId: (state) => state.customer.selectedCustomerId,
    }),
  },
};
</script>

<style scoped>
@media (max-width: 991px) {
  .noPadding {
    padding: 0;
  }
}
</style>
