<template>
  <div style="display: flex; justify-content: center; align-items: center; height: 100%; flex-direction: column;">
    <div v-if="!errStatus">
      <loader />
      <div>Loading BCF Academy...</div>
    </div>
    <div v-else>
      Something went wrong with loading BCF Academy
    </div>
  </div>
</template>

<script>
import loader from '@/elements/loader.vue';
import { GetMobietrainUrl } from '../api/api';

export default {
  name: 'Reset',
  components: {
    loader,
  },
  data: () => ({
    errStatus: false,
  }),
  async created() {
    try {
      const result = await GetMobietrainUrl();
      window.location.href = result;
    } catch (error) {
      this.errStatus = true;
      console.log(error);
    }
  },
};
</script>
