<template>
  <div class="list__item--cart__container">
    <!-- Cart List Item -->
    <div
      class="list__item list__item--cart"
    >
      <div class="list__item__image">
        <img
          :src="orderline.item.image ? orderline.item.image : `@/assets/img/product_placeholder.jpg`"
          :alt="orderline.item.externalid"
          loading="lazy"
        >
      </div>
      <div class="list__item__data">
        <div class="list__item__data__section list__item__data__section--badges">
          <Badge
            v-if="orderline.item.category === 'outlet' && !orderline.item.$$price.type > 1"
            type="outlet"
          >
            {{ $t('items.cat.outlet') }}
          </Badge>
          <Badge
            v-if="orderline.item.$$price.type > 1"
            type="promo"
          >
            {{ $t('general.promo') }}
          </Badge>
        </div>
        <div class="list__item__data__section list__item__data__section--title">
          <header>
            <div>
              <!-- Title -->
              <h4>{{ orderline.item.subtitle ? orderline.item.subtitle : "" }}</h4>
              <router-link
                v-if="orderline.item.itemid"
                :to="{name: 'itemdetail', params: {itemid: orderline.item.itemid}}"
                style="font-weight: bold; text-decoration: none;"
                target="_blank"
              >
                <h3>
                  {{ orderline.item.title }}<i
                    class="uil uil-external-link-alt"
                  />
                </h3>
              </router-link>
              <template v-else>
                <h3>{{ orderline.item.title }}</h3>
              </template>
              <div class="list__item__data__tags">
                <span
                  v-for="tag in orderline.item.tags"
                  :key="tag"
                  class="tag"
                >{{ tag }}</span>
              </div>
            </div>
            <!-- Properties -->
            <!-- Price -->
            <div class="list__item__data__entry--price">
              <div
                v-if="orderline.item.$$price.type > 1
                  && (
                    (showRetailPrices && orderline.item.$$price.oldmsrp)
                    || (!showRetailPrices && orderline.item.$$price.oldprice)
                  )"
                class="product__item__price"
              >
                <span class="oldprice">
                  <price
                    :price="showRetailPrices ? orderline.item.$$price.oldmsrp : orderline.item.$$price.oldprice"
                    :currency="orderline.item.$$price.currency"
                  />
                </span>
              </div>

              <div class="product__item__price">
                <span class="newprice">
                  <price
                    :price="showRetailPrices ? orderline.item.$$price.msrp : orderline.item.$$price.price"
                    :currency="orderline.item.$$price.currency"
                  />
                </span>
              </div>
            </div>
          </header>
        </div>
        <div class="list__item__data__section list__item__data__section--content">
          <div class="list__item__data__section--left">
            <div class="list__item__data__section__info-grid">
              <div class="list__item__data__entry list__item__data__entry--size">
                <template v-if="orderline.item.variantattribute && orderline.item.variantattribute.attribute">
                  <span class="label">{{ attributeTranslations[orderline.item.variantattribute.attribute]
                    ? attributeTranslations[orderline.item.variantattribute.attribute]
                    : orderline.item.variantattribute.attribute }}</span>
                  <span class="value">{{ orderline.item.variantattribute.value }}</span>
                </template>
              </div>
              <div class="list__item__data__entry list__item__data__entry--quantity">
                <span class="label">{{ $t('cart.quantity') }}</span>
                <span class="value">{{ orderline.orderline.quantity }}</span>
              </div>
              <div class="list__item__data__entry list__item__data__entry--date">
                <span class="label">{{ $t('cart.deliveryDate') }}</span>
                <time class="value">
                  {{ formatDate(orderline.available) }}
                </time>
              </div>
              <div
                v-if="!isCustomBike"
                class="list__item__data__entry list__item__data__entry--availablity"
              >
                <span class="label">{{ $t('items.stock') }}</span>
                <availability
                  :availability="orderline.item.$$availability"
                  quantity="1"
                  size="small"
                />
              </div>
            </div>
            <div
              v-if="orderline.orderline.remarks"
              class="list__item__data__entry list__item__data__entry--reference"
            >
              <span class="label">{{ $t('cart.remarks') }}</span>
              <span class="value">{{ orderline.orderline.remarks }}</span>
            </div>
            <div
              v-if="orderline.orderline.reference?.note"
              class="list__item__data__entry list__item__data__entry--cusnote"
            >
              <span class="label">{{ $t('account.account.myBackOrders.info.clientnote') }}</span>
              <expandable-text :text="orderline.orderline.reference?.note" />
            </div>
          </div>
          <div
            class="list__item__data__section--right"
          >
            <span class="list__item__data__entry"><span class="label">{{ $t('account.account.myBackOrders.info.actions') }}</span></span>
            <div
              class="list__item__data__actions"
              :class="{ 'unreferenced': !orderline.orderline.references}"
            >
              <div
                class="list__item__data__entry list__item__data__action list__item__data__action--customer"
                :class="{ 'referenced': orderline.orderline.references}"
                @click="assignItemToCustomer(orderline)"
              >
                <span class="label">{{ $t('account.account.myBackOrders.info.customer') }}</span>
                <template v-if="orderline.orderline.reference">
                  <div class="value">
                    {{ orderline.orderline.reference.firstName }} {{ orderline.orderline.reference.lastName }}
                  </div>
                  <div class="edit">
                    {{ $t('account.account.myBackOrders.info.edit') }}<i
                      class="uil uil-pen"
                    />
                  </div>
                </template>
                <span
                  v-else
                  class="edit"
                >{{ $t('account.account.myBackOrders.info.assign') }}<i
                  class="uil uil-user"
                />
                </span>
              </div>
              <router-link
                :to="{ name: 'cartItem', params: { id: orderline.orderline.key }}"
                class="list__item__data__entry list__item__data__action list__item__data__action--change"
              >
                <span class="label">{{ $t('cart.quantity') }} / {{ $t('cart.date') }}</span>
                <span class="value">
                  {{ $t('cart.change') }}<i
                    class="uil uil-pen"
                  />
                </span>
              </router-link>
              <div
                class="list__item__data__entry list__item__data__action list__item__data__action--remove"
                @click="removeItem"
              >
                <span class="value">
                  {{ $t('cart.remove') }}<i
                    class="uil uil-trash"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="hasErrors"
          class="list__item__data__section--errors"
        >
          <Message
            v-for="error in orderline.errors"
            :key="i + error"
            class="m-t-2"
            type="error"
            :icon="false"
            :show-close="false"
          >
            {{ $t('cart.messages.' + error) }}
          </Message>
          <Message
            v-for="warning in orderline.warnings"
            :key="i + warning"
            class="m-t-2"
            type="warning"
            :icon="false"
            :show-close="false"
          >
            {{ $t('cart.messages.' + warning) }}
          </Message>
        </div>
      </div>
    </div>
    <div
      v-if="!orderline.orderline.reference && (orderline.orderline.customizer || ['bikes'].includes(orderline.item.category?.toLowerCase()))"
      class="list__item__warning"
    >
      <span>{{ $t('cart.nouserassigned') }}</span>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import availability from '@/elements/Availability.vue';
import dialogs from '@/utils/dialogs';
import price from '@/elements/Price.vue';
import Badge from '@/elements/Badge.vue';
import { formatDate } from '@/utils/dateUtils';
import expandableText from '@/components/expandableText.vue';
import Message from '@/components/Message.vue';
import CartAssignCustomerDialog from '@/components/cart/CartAssignCustomerDialog.vue';

export default {
  components: {
    availability,
    price,
    Badge,
    expandableText,
    Message,
  },
  props: {
    orderline: {
      type: Object,
      required: true,
    },
  },
  mounted() {
  },
  computed: {
    ...mapState({
      attributeTranslations: (state) => state.language.attributeTranslations,
      showRetailPrices: (state) => state.items.showRetailPrices,
    }),
    hasErrors() {
      return this.orderline.errors.length > 0 || this.orderline.warnings.length > 0;
    },
    isCustomBike() {
      return this.orderline.orderline.customizer || false;
    },
  },
  methods: {
    formatDate,
    async removeItem() {
      const confirmed = await dialogs.show({
        title: `${this.$t('cart.remove')} ${this.orderline.item.title}?`,
        htmlContent: this.$t('cart.removeConfirmation'),
        type: 'cancelYes',
      });
      if (confirmed) {
        this.$store.dispatch('cart/removeItem', this.orderline.orderline.key);
      }
    },
    async assignItemToCustomer() {
      const result = await dialogs.show({
        component: CartAssignCustomerDialog,
        props: {
          reference: this.orderline.orderline.reference,
          orderline: {
            description: this.orderline.item.title,
          },
        },
      });
      if (result?.reference && result?.type === 'save') {
        const emptyRef = !(result.reference.firstName || result.reference.lastName || result.reference.email || result.reference.phone);
        this.$store.dispatch('cart/patchCartItem', {
          key: this.orderline.orderline.key,
          patch: {
            reference: emptyRef ? undefined : result.reference,
          },
        });
      }
    },
  },
};
</script>
