<template>
  <div>
    <loading-overlay :loading="inProgress">
      <h1>{{ $t("login.title") }}</h1>

      <div
        v-if="errorMessage"
        class="m-b-4"
      >
        <message type="error">
          {{ $t(`api.${errorMessage}`) }}
        </message>
      </div>
      <search
        v-if="!inProgress"
        v-model="searchQ"
        class="m-md-t-2"
        @input="search"
      />
      <div
        v-if="!inProgress"
        class="list"
      >
        <div
          v-for="customer in customersResults"
          :key="customer.id"
          :class="{blocked: customer.attributes.blocked}"
          @click="change(customer)"
        >
          <ListItemAccount :selected="(selectedCustomerId === customer.id)">
            <span>
              <strong>{{ customer.name }}</strong>
              <template
                v-if="totalBalance(customer.attributes) > customer.attributes.CreditLimitLCY && customer.attributes.CreditLimitLCY > 0"
              >
                <small>
                  <Badge>{{ $t('account.account.myBalance.status') }}</Badge>
                </small>
              </template>
            </span>
            <small>{{ customer.externalid }} <span v-show="customer.attributes.blocked"> - not active</span></small>
          </ListItemAccount>
        </div>
      </div>

      <emptystate v-if="customersResults.length === 0 && !inProgress">
        Geen resultaten
      </emptystate>
    </loading-overlay>
  </div>
</template>

<style scoped>
</style>

<script>
import { mapState } from 'vuex';
import emptystate from '@/elements/EmptyState.vue';
import ListItemAccount from '@/elements/ListItemAccount.vue';
import search from '@/components/Search.vue';
import Badge from '@/elements/Badge.vue';
import loadingOverlay from '@/elements/LoadingOverlay.vue';
import { GetCustomerByUserId } from '@/api/api'; /** Context */

export default {
  components: {
    ListItemAccount,
    emptystate,
    search,
    Badge,
    loadingOverlay,
  },
  data() {
    return {
      searchQ: '',
      customersResults: [],
      customers: [],
      errorMessage: null,
      inProgress: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      selectedCustomerId: (state) => state.customer.selectedCustomerId,
      isPreviewMode: (state) => state.global.isPreviewMode,
    }),
  },
  mounted() {
    this.searchQ = null;
    this.loadCustomers();
  },
  methods: {
    async loadCustomers() {
      try {
        this.errorMessage = null;
        this.inProgress = true;
        this.customers = await GetCustomerByUserId(this.user.id);
        this.customersResults = Object.freeze(this.customers);
      } catch (error) {
        this.errorMessage = error.message;
      } finally {
        this.inProgress = false;
      }
    },
    totalBalance(customer) {
      return Number(customer.BalanceProductionOrdersLCY) + Number(customer.BalanceOpenInvoicesLCY);
    },
    async change(customer) {
      await this.$store.dispatch('customer/setSelectedCustomer', {
        selectedCustomer: customer,
      });
      this.$emit('changed', true);
    },
    search() {
      if (this.searchQ.length > 0) {
        this.$search(this.searchQ, this.customers, {
          keys: ['name', 'externalid'],
          defaultAll: true,
          caseSensitive: false,
          shouldSort: true,
          tokenize: false,
          matchAllTokens: true,
          findAllMatches: true,
          threshold: 0.6,
          location: 0,
          distance: 100,
          maxPatternLength: 32,
          minMatchCharLength: 1,
        }).then((results) => {
          this.customersResults = Object.freeze(results);
        });
      } else {
        this.customersResults = Object.freeze(this.customers);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.blocked {
  opacity: 0.7;
}
</style>
